

function Header() {
    return (
      <header className="title-header">
          <a className="title-sober" href='https://teerkost.nl'>
            <span>Teerkost
              {/* <div className="new"></div> */}
            </span>
            </a>
      </header>
    )
}

export default Header;