import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import ProductCardSingle from "../components/ProductCardSingle";

function SingleProduct(props) {

  useEffect(() => {
    window.scrollTo(0, 0);
  });


    return (
      <div className="dialog-wrap" id="single-dialog-on">
        <Link to="/"><div className="home-button-item-single">sluiten</div></Link>   
        <ProductCardSingle item={props.item}/>
      </div>

    )
}

export default SingleProduct;
