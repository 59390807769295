import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { HashRouter } from "react-router-dom";

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <HashRouter>
    <App />
  </HashRouter>)

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/serviceworker.js')
    .then(() => { console.log('Service Worker Registered'); });
}