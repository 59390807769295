import { Link } from "react-router-dom";
import React, {useEffect } from "react";

function SearchButton(props) {
    
    useEffect(() => {
    },[])

    if(props.active !== undefined) {
        return (
            <div className="button-cell active">
               <Link to="/zoek"><div className="button search-button">
                <i className="ri-search-fill"></i>
                <div className="button-tag">zoek</div>
                </div></Link> 
            </div>
        )
    } else {
        return (
            <div className="button-cell">
               <Link to="/zoek"><div className="button search-button">
                <i className="ri-search-line"></i>
                <div className="button-tag">zoek</div>
                </div></Link> 
            </div>
        )
    }

  }
  
  export default SearchButton;
    