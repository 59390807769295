import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";

function GoHomeButton(props) {

    useEffect(() => {
    },[])

    if (props.active !== undefined) {
        return (
            <div className="button-cell go-home-nav-element active">
                <Link to="/">
                    <div className="button home-button">
                        <i className="ri-home-2-fill"></i>
                        <div className="button-tag">teerkost</div>
                    </div>
                </Link>
            </div>
        );
    } else {
        return (
            <div className="button-cell go-home-nav-element">
                <Link to="/">
                    <div className="button home-button">
                        <i className="ri-home-2-line"></i>
                        <div className="button-tag">teerkost</div>
                    </div>
                </Link>
            </div>
        );
    }
}

export default GoHomeButton;
