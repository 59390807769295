function NoResults() {
    return (
        <div className="no-offers-search">
          <div className='notify-wrap'>
            Geen zoekresultaten
          </div>
        </div>
    )
}

export default NoResults;
