import React, { useState, useEffect, Suspense } from "react";
import { useParams } from "react-router-dom";
import Offers from "../offers.json";
import ShareDialog from "../components/ShareDialog";
import BookmarkButton from "../BookmarkButton";
import GoHomeButton from "../GoHomeButton";
import ShareButton from "../ShareButton";
import SearchButton from "../SearchButton";
import Header from "../components/Header";
import OptionsButton from "../OptionsButton";
import NoResults from "../NoResults";
import localForage from "localforage";

const ProductsContainer = React.lazy(() => import("../ProductsContainer"));

function SearchProduct(props) {
    const [savedSearchTerms, setSavedSearchTerms] = useState([]);
    const [selectedOffers, setSelectedOffers] = useState(Offers);

    useEffect(() => {
        collectedSavedSearchTerms();
    }, []); // [] to update once

    useEffect(() => {
        window.scrollTo(0, 0);

        // FIXME event is bubbeling
        let searchInput = document.getElementById("search");
        searchInput.addEventListener("keyup", (e) => {
            // blur on enter press
            e.preventDefault();
            if (!e.repeat && e.key === "Enter") {
                doBlur();
            } else {
                setFullFocus();
            }
        });
    });

    const updatePageTitle = () => {
        document.title = "zoeken - Teerkost";
    };

    const setAsSearchInput = (searchValue) => {
        let offersBySearch = Offers.filter(
            (object) =>
                object.product.toLowerCase().includes(searchValue.toLowerCase()) ||
                object.productInfo.toLowerCase().includes(searchValue.toLowerCase()) ||
                object.category.toLowerCase().includes(searchValue.toLowerCase())
        );
        setSelectedOffers(offersBySearch);

        let searchInput = document.getElementById("search");
        searchInput.value = searchValue;
        doBlur();
    };

    const collectedSavedSearchTerms = () => {
        localForage
            .getItem("teerkost-search")
            .then((value) => {
                if (value !== null) {
                    console.log("in state: " + value);
                    setSavedSearchTerms(value.reverse());
                }
            })
            .catch((err) => {
                console.log("Iets misgegaan bij ophalen zoektermen: " + err);
            });
    };

    const deleteSavedSearchTerm = (searchTerm) => {
        localForage
            .getItem("teerkost-search")
            .then((value) => {
                // get existing data first
                let savedSearchArray = "";
                if (value !== null) {
                    savedSearchArray = value; // has to be an array
                    if (savedSearchArray.includes(searchTerm)) {
                        const index = savedSearchArray.indexOf(searchTerm);
                        savedSearchArray.splice(index, 1);
                        setSavedSearchTerms(savedSearchArray);
                    }
                    localForage.setItem("teerkost-search", savedSearchArray).catch((err) => {
                        console.log("Iets misgegaan bij het verwijderen van zoekterm: " + err);
                    });
                }
            })
            .catch((err) => {
                console.log("iets misgegaan bij ophalen zoektermen: " + err);
            });
    };

    const saveSearchInputForLater = () => {
        let searchInput = document.getElementById("search");
        let searchValue = searchInput.value.replace(/\s+/g, " ").trim(); // clean up whitespace
        if (searchValue.length !== 0) {
            localForage
                .getItem("teerkost-search")
                .then((value) => {
                    // get existing data first
                    let savedSearchArray = "";
                    if (value !== null) {
                        savedSearchArray = value; // has to be an array
                        if (!savedSearchArray.includes(searchValue)) {
                            // check if already saved
                            savedSearchArray.push(searchValue);
                            setSavedSearchTerms(savedSearchArray.reverse());
                        }
                    } else {
                        savedSearchArray = [searchValue];
                    }
                    localForage.setItem("teerkost-search", savedSearchArray).catch((err) => {
                        console.log("Iets misgegaan bij het bewaren van zoekterm: " + err);
                    });
                })
                .catch((err) => {
                    console.log("iets misgegaan bij ophalen zoektermen: " + err);
                });
        }
    };

    const setSearchFilter = (searchValue) => {
        let offersBySearch = Offers.filter(
            (object) =>
                object.product.toLowerCase().includes(searchValue.toLowerCase()) ||
                object.productInfo.toLowerCase().includes(searchValue.toLowerCase()) ||
                object.category.toLowerCase().includes(searchValue.toLowerCase())
        );
        setSelectedOffers(offersBySearch);
    };

    const handleKeyPress = (e) => {
        let inputvalue = e.target.value.trim();
        if (inputvalue.length !== 0) {
            // check if whitespace
            setSearchFilter(inputvalue.trim());
        } else {
        }
    };

    var doBlur = function (e) {
        let searchInput = document.querySelector("#search");
        let wrapElement = document.querySelector(".dialog-wrap");
        wrapElement.removeAttribute("id");
        if (!wrapElement.hasAttribute("id")) {
            saveSearchInputForLater();
            return searchInput.removeEventListener("blur", doBlur);
        }
    };

    const setFullFocus = () => {
        let searchInput = document.querySelector("#search");
        let wrapElement = document.querySelector(".dialog-wrap");

        if (!wrapElement.hasAttribute("id")) {
            wrapElement.id = "search-dialog-on";
            if (wrapElement.id === "search-dialog-on") {
                searchInput.addEventListener("blur", doBlur);
            }
        }
    };

    useEffect(() => {
        updatePageTitle();
    }, [selectedOffers]);

    return (
        <div className="app-wrap">
            <Header></Header>
            <ShareDialog buttonText="Deel pagina" infoText="Deel een link naar deze pagina." />
            <div className="bottom-buttons">
                <GoHomeButton />
                <SearchButton active="active"/>
                <OptionsButton />
                <BookmarkButton />
                <ShareButton buttonText="deel" infoText="Deel een link naar deze pagina." />
            </div>
            <div className="search-dialog">
                <header className="header-title search">
                    <input
                        onChange={(e) => handleKeyPress(e)}
                        onFocus={() => setFullFocus()}
                        className="filter-search"
                        type="text"
                        id="search"
                        placeholder="Zoek een aanbieding..."
                        autoFocus
                    ></input>
                </header>
                {savedSearchTerms.length > 0 ? (
                    <div id="recent" className="filter-search-recent">
                        <span className="label">Zoektermen:</span>
                        {savedSearchTerms.map((value) => (
                            <div key={value} className="recent-search">
                                <div className="term" onClick={() => setAsSearchInput(value)}>
                                    {value}
                                </div>
                                <i
                                    onClick={() => deleteSavedSearchTerm(value)}
                                    className="delete ri-close-line"
                                ></i>
                            </div>
                        ))}
                    </div>
                ) : (
                  <div id="recent" className="filter-search-recent recent-dummy">
                  </div>
                )}
            </div>
            {selectedOffers.length > 0 ? (
                <Suspense
                    fallback={
                        <div className="even-geduld">
                            <div className="notify-wrap">
                                <span className="dot-dot-dot"></span>
                            </div>
                        </div>
                    }
                >
                    <ProductsContainer selectedOffers={selectedOffers} />
                </Suspense>
            ) : (
                <NoResults></NoResults>
            )}
        </div>
    );
}

export default SearchProduct;
