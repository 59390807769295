export const categoryList = [
    "groente",
    "vlees",
    "vis",
    "fruit",
    "vega",
    "bier",
    "zuivel",
    "kant-en-klaar",
    "wijn",
    "aardappel",
    "brood",
    "kaas",
    "noten",
    "tapas",
    "koffie",
    "thee",
    "beleg",
    "ontbijt",
    "sap",
    "siroop",
    "frisdrank",
    "pasta",
    "sauzen",
    "ijs",
    "zoetigheid",
    "chips",
    "snoep",
    "gebak",
    "verzorging",
    "huishouden",
    "tuin",
    "dier",
    "geen-categorie"
];
