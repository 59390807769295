import React, { useState, useEffect, Suspense } from "react";
import Offers from "../offers.json";
import localForage from "localforage";
import ShareDialog from "../components/ShareDialog";
import SearchButton from "../SearchButton";
import GoHomeButton from "../GoHomeButton";
import BookmarkButton from "../BookmarkButton";
import { categoryList } from "../Categories";
import ShareButton from "../ShareButton";
import Header from "../components/Header";
import OptionsButton from "../OptionsButton";
import SingleProduct from "./SingleProduct";

const ProductsContainer = React.lazy(() => import("../ProductsContainer"));

function Main(props) {
    const categories = [];

    // FIXME  denk ik niet meer nodig
    categoryList.forEach((element) => {
        categories.push([element, 0]); // for populating the category filters on screen
    });

    let fullShopList = [
        "ah",
        "jumbo",
        "lidl",
        "aldi",
        "spar",
        "plus",
        "ekoplaza",
        "poiesz",
        "dirk",
        "dekamarkt",
        "hoogvliet",
    ];

    let allShops = [
        ["ah", 0],
        ["jumbo", 0],
        ["lidl", 0],
        ["aldi", 0],
        ["spar", 0],
        ["plus", 0],
        ["ekoplaza", 0],
        ["poiesz", 0],
        ["dirk", 0],
        ["dekamarkt", 0],
        ["hoogvliet", 0],
    ];

    const sortedOffers = Offers.sort(function (a, b) {
        // sorting according to categorySorting array
        return categoryList.indexOf(a.category) - categoryList.indexOf(b.category);
    });

    const [categoriesList, setCategoriesList] = useState([]);
    const [shopsList, setShopsList] = useState([]);
    const [selectedOffers, setSelectedOffers] = useState(sortedOffers);
    const [selectedShopRoute, setSelectedShopRoute] = useState(props.shop);
    const [selectedDealRoute, setSelectedDealRoute] = useState(props.deal);
    const [selectedCatRoute, setSelectedCatRoute] = useState(props.cat);

    /**
     * Maakt een lijst van te tonen winkels en haalt winkels weg die in Indexed DB staan (teerkost-ignore-shops)
     */
    const createListOfShops = async () => {
        try {
            const ignoredShops = await localForage.getItem("teerkost-ignore-shops");
            let filteredShopList = [...fullShopList];

            if (ignoredShops !== null) {
                // Remove ignored shops from the list
                ignoredShops.forEach((shop) => {
                    const index = filteredShopList.indexOf(shop);
                    if (index !== -1) {
                        filteredShopList.splice(index, 1);
                    }
                });
            }

            // Create a list with shop objects
            const fullShopListCompleted = filteredShopList.map((shop) => returnObjectShopWithOfferCount([shop, 0]));

            console.log("Lijst van winkels gemaakt op basis van voorkeur: ", fullShopListCompleted.length);

            setShopsList(fullShopListCompleted);
        } catch (err) {
            console.log("Iets misgegaan bij ophalen te negeren winkels: " + err);
        }
    };

    const createListOfCategories = async () => {
        try {
            const ignoredCategories = await localForage.getItem("teerkost-ignore-categories");
            const allCategoriesFiltered = [];

            if (ignoredCategories !== null) {
                const categoryListCopy = categoryList.slice();

                // Remove ignored categories from the copy
                ignoredCategories.forEach((category) => {
                    const index = categoryListCopy.indexOf(category);
                    if (index !== -1) {
                        categoryListCopy.splice(index, 1);
                    }
                });

                categoryListCopy.forEach((element) => {
                    allCategoriesFiltered.push([element, 0]);
                });
            } else {
                // If no ignored categories, use the full list with count value of 0
                categoryList.forEach((category) => {
                    allCategoriesFiltered.push([category, 0]);
                });
            }

            const allCategoriesFilteredAndCounted = allCategoriesFiltered.map((category) => {
                const categoryFilter = (object) => object.category === category[0];

                let filterByCategory = Offers.filter(categoryFilter);

                if (selectedShopRoute !== undefined) {
                    const shopFilter = (object) => object.shop === selectedShopRoute;
                    filterByCategory.filter(shopFilter).filter(categoryFilter);
                }

                return [category[0], filterByCategory.length];
            });

            setCategoriesList(allCategoriesFilteredAndCounted);

            console.log(
                "Lijst van categorieën gemaakt op basis van voorkeur: ",
                allCategoriesFilteredAndCounted.length
            );
        } catch (err) {
            console.log("Iets misgegaan bij ophalen te negeren categorieën: " + err);
        }
    };

    const returnObjectShopWithOfferCount = (shop) => {
        var shopFilter = (object) => object.shop === shop[0];

        var filterByShop = Offers.filter(shopFilter);
        var numberCountByShop = filterByShop.length;

        return [shop[0], numberCountByShop];
    };

    useEffect(() => {
        setScrollHeight();
        setFilterNotStickyOnSmallScreen();
    });

    useEffect(() => {
        createListOfShops();
        createListOfCategories();
    }, []);

    useEffect(() => {
        if (categoriesList.length !== 0) {
            updateOfferList(selectedShopRoute, selectedCatRoute, selectedDealRoute);
        }
    }, [categoriesList]);

    useEffect(() => {
        if (shopsList.length !== 0 && categoriesList.length !== 0) {
            updateOfferList(selectedShopRoute, selectedCatRoute, selectedDealRoute);
            updatePageTitle(selectedShopRoute, selectedCatRoute);
            setCounterPerCategory(selectedShopRoute);
        }

        toggleFilterButton(selectedShopRoute, "shop");
        toggleFilterButton(selectedCatRoute, "category");
        toggleFilterButton(selectedDealRoute, "deal");
    }, [shopsList]);

    useEffect(() => {
        toggleFilterButton(selectedShopRoute, "shop");
        toggleFilterButton(selectedCatRoute, "category");
        toggleFilterButton(selectedDealRoute, "deal");
    }, []);

    const setFilterNotStickyOnSmallScreen = () => {
        var checkSticky = () => {
            var filterWrapElement = document.querySelector(".filter");
            var minHeight = 1200; // hardcoded because height of element is not known on mount
            var heightWindow = window.innerHeight;
            if (heightWindow < minHeight) {
                filterWrapElement.classList.add("not-sticky"); // make it not sticky
            } else {
                filterWrapElement.classList.remove("not-sticky"); // make it sticky
            }
        };

        // on load
        checkSticky();
        // and on resize
        window.onresize = () => {
            checkSticky();
        };
    };

    const setCounterPerCategory = (shop) => {
        let shopFilter = false;
        if (shop !== undefined) {
            shopFilter = (object) => object.shop === shop;
        } else {
            shopFilter = (object) => returnListWithoutCount(shopsList).includes(object.shop);
        }

        let tempList = [];
        categoriesList.map((category) => {
            var categoryFilter = (object) => object.category === category[0];

            var filterByCategory = Offers.filter(shopFilter).filter(categoryFilter);
            var numberCountByCategory = filterByCategory.length;

            let categoryObjectTemp = [category[0], numberCountByCategory];
            return tempList.push(categoryObjectTemp);
        });
        setCategoriesList(tempList);
    };

    const updateUrl = (shopName, catName) => {
        var shopUrlPart = "";
        var catUrlPart = "";
        if (shopName !== undefined) {
            shopUrlPart = "/#/" + shopName;
        } else {
            shopUrlPart = "/#/alle-winkels";
        }
        if (catName !== undefined) {
            if (shopName === undefined) {
                shopUrlPart = "/#/alle-winkels";
            }
            catUrlPart = "/" + catName;
        }
        window.history.pushState({}, "", shopUrlPart + catUrlPart);
        updatePageTitle(shopName, catName);
    };

    const updatePageTitle = (shopName, catName) => {
        var shopPart = "";
        var catPart = "";
        var titlePart = "Teerkost";
        if (shopName !== undefined) {
            shopPart = " " + shopName + " - ";
        }
        if (catName !== undefined) {
            if (shopName === undefined) {
                shopPart = " ";
            }
            catPart = catName + " - ";
        }
        if (shopName !== undefined || catName !== undefined) {
            titlePart = "Teerkost";
        }
        document.title = catPart + "" + shopPart + titlePart;
    };

    const clickOnShop = (name) => {
        toggleFilterButton(name, "shop");
        if (selectedShopRoute === name) {
            name = undefined;
        }
        setSelectedShopRoute(name);
        setCounterPerCategory(name);
        updateOfferList(name, selectedCatRoute, selectedDealRoute);
        updateUrl(name, selectedCatRoute);
    };

    const clickOnCat = (name) => {
        toggleFilterButton(name, "category");
        if (selectedCatRoute === name) {
            name = undefined;
        }
        setSelectedCatRoute(name);
        updateOfferList(selectedShopRoute, name, selectedDealRoute);
        updateUrl(selectedShopRoute, name);

        toggleFilterGrid("cat", true);
    };

    const clickOnDeal = (name) => {
        toggleFilterButton(name, "deal");
        if (selectedDealRoute === name) {
            name = undefined;
        }
        setSelectedDealRoute(name);
        updateOfferList(selectedShopRoute, selectedCatRoute, name);
    };

    const toggleFilterButton = (name, type) => {
        var htmlIdActive = "active-" + type;
        var element = document.querySelectorAll(`[data-${type}="${name}"]`);
        var node = element.item(0);
        if (node != null) {
            if (node.hasAttribute("id")) {
                node.removeAttribute("id"); // deactivate the clicked element
            } else {
                var activeElement = document.getElementById(htmlIdActive);
                if (activeElement != null) {
                    activeElement.removeAttribute("id");
                } // deactivate any other element

                node.id = htmlIdActive; // activate the clicked element
            }
        }
    };

    const setScrollHeight = () => {
        var titleElementHeight = document.querySelector(".title-sober").offsetHeight + 10;
        var scrollHeight = window.scrollY;
        if (scrollHeight >= titleElementHeight) {
            window.scrollTo(0, titleElementHeight); // Scroll to top, but below title element
        } else if (scrollHeight !== 0) {
            window.scrollTo(0, scrollHeight); // Keep scroll position with logo partial in view
        } else {
            window.scrollTo(0, 0); // Go to top
        }
    };

    const updateOfferList = async (selectedShopRoute, selectedCatRoute, selectedDealRoute) => {
        let categoryFilter = (object) => returnListWithoutCount(categoriesList).includes(object.category);
        let shopFilter = (object) => returnListWithoutCount(shopsList).includes(object.shop);
        let dealFilter = (object) => object.deal !== null;

        if (selectedCatRoute !== undefined) {
            categoryFilter = (object) => object.category === selectedCatRoute;
            console.log("Filter voor category met " + selectedCatRoute + " ingesteld.");
        }

        if (selectedDealRoute !== undefined) {
            if (parseInt(selectedDealRoute) === 0) {
                dealFilter = (object) => object.percentage === 0;
                console.log("percentage moet nul zijn");
            } else {
                dealFilter = (object) => object.percentage >= selectedDealRoute;
            }
            console.log("Filter voor deal met " + selectedDealRoute + " ingesteld.");
        }

        if (selectedShopRoute !== undefined) {
            shopFilter = (object) => object.shop === selectedShopRoute;
            console.log("Filter voor shop met " + selectedShopRoute + " ingesteld.");
        }
        console.log("Totale aantal aanbiedingen: " + Offers.length);

        Offers.sort(function (a, b) {
            // sorting according to categorySorting array
            return categoryList.indexOf(a.category) - categoryList.indexOf(b.category);
        });

        var filtered = Offers.filter(shopFilter).filter(dealFilter).filter(categoryFilter);

        setSelectedOffers(filtered);
        console.log("Aantal aanbiedingen na filter: " + filtered.length);
    };

    const returnListWithoutCount = (listWithCount) => {
        var simpleList = [];

        listWithCount.forEach((element) => simpleList.push(element[0]));
        return simpleList;
    };

    /**
     * Toggle the filterlist as a grid instead of a no-wrap scrollable list */
    const toggleFilterGrid = (filter, removeAsGrid) => {
        const filterElement = document.querySelector(".filter-".concat(filter));
        if (!filterElement.classList.contains("as-grid")) {
            if (removeAsGrid === true) {
                filterElement.classList.remove("as-grid");
            } else {
                filterElement.classList.add("as-grid");
            }
        } else {
            filterElement.classList.remove("as-grid");
        }
    };

    return (
        <div className="app-wrap">
            {props.singleItem !== undefined ? <SingleProduct item={props.singleItem} /> : <div></div>}{" "}
            {/* individuele aanbieding tonen over de pagina heen */}
            <ShareDialog buttonText="Deel pagina" infoText="Deel de huidige pagina met de gekozen filters." />
            <div className="bottom-buttons">
                <GoHomeButton active="active" />
                <SearchButton />
                <OptionsButton />
                <BookmarkButton />
                <ShareButton buttonText="deel" infoText="Deel de huidige pagina met de gekozen filters." />
            </div>
            <Header></Header>
            <header className="filter">
                <div id="filter-dialog">
                    <div className="filter-wrap">
                        <div className="filter-shop">
                            {shopsList.map((shop, key) => {
                                if (shop[1] === 0) {
                                    return (
                                        <button
                                            title={"Momenteel geen aanbiedingen gevonden voor " + shop[0]}
                                            className="filter-shop-no-interest"
                                            key={key}
                                            onClick={() => clickOnShop(shop[0])}
                                            data-shop={shop[0]}
                                        >
                                            {shop[0]}
                                        </button>
                                    );
                                } else {
                                    return (
                                        <button key={shop} onClick={() => clickOnShop(shop[0])} data-shop={shop[0]}>
                                            {shop[0]} <i className="counter">{shop[1]}</i>
                                        </button>
                                    );
                                }
                            })}
                        </div>
                        <div className="filter-cat">
                            {categoriesList.length > 5 && (
                                <button
                                    title="Toon categorieën onder elkaar"
                                    onClick={() => toggleFilterGrid("cat", false)}
                                    className="filter-view-toggle"
                                >
                                    <i className="ri-arrow-down-s-line"></i>
                                </button>
                            )}

                            <span className="cat-list">
                                {categoriesList.map((category, key) => {
                                    if (category[0] !== "") {
                                        // don't show empty filters
                                        if (category[1] === 0) {
                                            return (
                                                <button
                                                    className="filter-no-interest"
                                                    key={key}
                                                    onClick={() => clickOnCat(category[0])}
                                                    data-category={category[0]}
                                                >
                                                    {category[0]}
                                                </button>
                                            );
                                        } else {
                                            return (
                                                <button
                                                    key={key}
                                                    onClick={() => clickOnCat(category[0])}
                                                    data-category={category[0]}
                                                >
                                                    {category[0]} <i className="counter">{category[1]}</i>
                                                </button>
                                            );
                                        }
                                    } else {
                                        return "";
                                    }
                                })}
                            </span>
                        </div>
                        <div className="filter-deal">
                            <button onClick={() => clickOnDeal("10")} data-deal="10">
                                {"≥ 10%"}
                            </button>
                            <button onClick={() => clickOnDeal("20")} data-deal="20">
                                {"≥ 20%"}
                            </button>
                            <button onClick={() => clickOnDeal("30")} data-deal="30">
                                {"≥ 30%"}
                            </button>
                            <button onClick={() => clickOnDeal("40")} data-deal="40">
                                {"≥ 40%"}
                            </button>
                            <button onClick={() => clickOnDeal("50")} data-deal="50">
                                {"≥ 50%"}
                            </button>
                            <button onClick={() => clickOnDeal("0")} data-deal="0">
                                {"geen %"}
                            </button>
                        </div>
                    </div>
                </div>
            </header>
            <Suspense
                fallback={
                    <div className="even-geduld">
                        <div className="notify-wrap">
                            <span className="dot-dot-dot"></span>
                        </div>
                    </div>
                }
            >
                <ProductsContainer selectedOffers={selectedOffers} />
            </Suspense>
        </div>
    );
}
export default Main;
