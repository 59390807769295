import React, { useEffect, useState } from "react";

function ShareDialog(props) {

    const [shareUrl, setShareUrl] = useState()
    // let shareUrl = window.location.href;
    let buttonText = props.buttonText;
    let infoText = props.infoText;
    let shareText = "Altijd de actuele aanbiedingen van supermarkten.";
    let shareTitle = document.title;

    useEffect(() => {
        if(props.customUrl !== undefined) {
            setShareUrl(props.customUrl)
        } else {
            setShareUrl(window.location.href)
        }
    }, [props]);

    const copyMe = () => {
        let linkElement = document.querySelector(".url-share input");
        navigator.clipboard.writeText(linkElement.value);
        console.log(shareUrl + " gekopieerd");
        let confirmElement = document.querySelector(".url-share .copy-confirm");
        confirmElement.classList.add("hide"); // reset animation
        void confirmElement.offsetWidth; // trigger reflow
        setTimeout(function () {
            confirmElement.classList.remove("hide"); // start animation
        }, 3000);
    };

    const copyMeTextarea = () => {
        let linkElement = document.querySelector(".list-share textarea");
        navigator.clipboard.writeText(linkElement.value);
        console.log("Bewaarlijst gekopieerd");
        let confirmElement = document.querySelector(".list-share .copy-confirm");
        confirmElement.classList.add("hide"); // reset animation
        void confirmElement.offsetWidth; // trigger reflow
        setTimeout(function () {
            confirmElement.classList.remove("hide"); // start animation
        }, 3000);
    };

    return (
        <div className="dialog-wrap">
            {/* <div className="info-dialog">
            <span className="info-dialog-title">Over Teerkost</span>
            <div className="info-dialog-info">
                Teerkost verzamelt elke dag de actuele aanbiedingen van de meeste supermarkten in Nederland.
                <br></br><br></br>
                Aanbiedingen kan je bewaren door op DERP te klikken. Ze staan dan opgeslagen op je apparaat in de vorm van een cookie.
                <br></br>
                <br></br>
                Het enige doel van Teerkost is aanbiedingen tonen. Er is geen verdienmodel.
                Teerkost wordt beheerd door David Duyff en is open source op Github.
                <br></br>
                <br></br>
            </div>
            </div> */}
            <div className="copy-dialog">
                <span className="share-title">Kopieer lijst</span>
                <span className="share-info">Kopieer de bewaarde korting als een boodschappenlijst.</span>
                <div className="list-share">
                    <textarea></textarea>
                    <span onClick={copyMeTextarea} className="list-copy">
                        <i className="ri-file-copy-2-line"></i>
                        <span className="label">kopieer</span>
                    </span>
                    <div className="copy-confirm">lijst gekopieerd!</div>
                </div>
            </div>
            <div className="share-dialog">
                <span className="share-title">{buttonText}</span>
                <span className="share-info">{infoText}</span>
                <div className="url-share">
                    <input defaultValue={shareUrl} />
                    <span onClick={copyMe} className="url-copy">
                        <i className="ri-file-copy-2-line"></i>
                        <span className="label">kopieer</span>
                    </span>
                    <div className="copy-confirm">link gekopieerd!</div>
                </div>
                <div className="socials">
                    <a className="whatsapp" href={"https://api.whatsapp.com/send?text=" + shareUrl + ""}>
                        <i className="ri-whatsapp-fill"></i>
                    </a>
                    <a
                        className="telegram"
                        href={"https://telegram.me/share/url?url=" + shareUrl + "&text=" + shareText + ""}
                    >
                        <i className="ri-telegram-fill"></i>
                    </a>
                    <a
                        className="twitter"
                        href={"https://twitter.com/intent/tweet?url=" + shareUrl + "&text=" + shareText + ""}
                    >
                        <i className="ri-twitter-fill"></i>
                    </a>
                    <a
                        className="reddit"
                        href={"https://reddit.com/submit?url=" + shareUrl + "&title=" + shareTitle + ""}
                    >
                        <i className="ri-reddit-fill"></i>
                    </a>
                    <a
                        className="linkedin"
                        href={"https://www.linkedin.com/sharing/share-offsite/?url=" + shareUrl + ""}
                    >
                        <i className="ri-linkedin-box-fill"></i>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default ShareDialog;
