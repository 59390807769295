function Price(props) {

    const price = props.newPrice;
    

    if(price !== 0 && price !== "0" && price !== "") {
        return (
            <span className="product-price">{new Intl.NumberFormat('en-IN', {
                maximumFractionDigits: 2, minimumFractionDigits: 2
              }).format(price)}</span> 
        )
    }
    else {
        return (<span title='Actuele prijs is onbekend' className="product-price product-unknown">?</span>)
    }
}

export default Price;
  