import React, { useState, useEffect } from "react";
import ShareDialog from "../components/ShareDialog";
import localForage from "localforage";
import OptionsButton from "../OptionsButton";
import BookmarkButton from "../BookmarkButton";
import ShareButton from "../ShareButton";
import GoHomeButton from "../GoHomeButton";
import SearchButton from "../SearchButton";
import Header from "../components/Header";
import { categoryList } from "../Categories";

function Options(props) {
    const [ignoredShopState, setIgnoredShopState] = useState([]);
    const [ignoredCategoryListState, setIgnoredCategoryListState] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const updatePageTitle = () => {
        document.title = "opties - Teerkost";
    };

    const saveDarkModeIndexedDb = (dark) => {
        localForage
            .setItem("teerkost-darkmode", dark)
            .then(function (value) {
                console.log("Opgeslagen donkere modus actief: " + dark);
            })
            .catch(function (err) {
                console.log("Iets misgegaan bij het opslaan van donkere modus: " + err);
            });
    };

    const setDark = (dark) => {
        let rootElement = document.documentElement;

        if (dark === false) {
            rootElement.classList.remove("dark");
            saveDarkModeIndexedDb(false);
        } else {
            rootElement.classList.add("dark");
            saveDarkModeIndexedDb(true);
        }
    };

    const setIgnoreShop = (shop) => {
        var element = document.querySelectorAll(`[data-shop="${shop}"]`);
        if (element[0].classList.contains("ignore-shop")) {
            element[0].classList.remove("ignore-shop");
            deleteShopIgnore(shop);
        } else {
            element[0].classList.add("ignore-shop");
            saveShopIgnore(shop);
        }
    };

    const setIgnoreCategory = (category) => {
        var element = document.querySelectorAll(`[data-category="${category}"]`);
        if (element[0].classList.contains("ignore-category")) {
            element[0].classList.remove("ignore-category");
            deleteCategoryIgnore(category);
        } else {
            element[0].classList.add("ignore-category");
            saveCategoryIgnore(category);
        }
    };

    const setIgnoreShopOnPageLoad = (value) => {
        value.forEach((shop) => {
            var element = document.querySelectorAll(`[data-shop="${shop}"]`);
            element[0].classList.add("ignore-shop");
        });
    };

    const setIgnoreCategoryOnPageLoad = (value) => {
        value.forEach((category) => {
            var element = document.querySelectorAll(`[data-category="${category}"]`);
            element[0].classList.add("ignore-category");
        });
    };

    const collectIgnoredCategories = () => {
        localForage
            .getItem("teerkost-ignore-categories")
            .then((value) => {
                if (value !== null) {
                    setIgnoredCategoryListState(value); // update state from existing data
                    setIgnoreCategoryOnPageLoad(value); // set category to ignored visually
                }
            })
            .catch((err) => {
                console.log("Iets misgegaan bij ophalen te negeren categorieën: " + err);
            });
    };

    const collectIgnoredShops = () => {
        localForage
            .getItem("teerkost-ignore-shops")
            .then((value) => {
                if (value !== null) {
                    setIgnoredShopState(value); // update state from existing data
                    setIgnoreShopOnPageLoad(value); // set shops to ignored visually
                }
            })
            .catch((err) => {
                console.log("Iets misgegaan bij ophalen te negeren winkels: " + err);
            });
    };

    const deleteShopIgnore = (shop) => {
        localForage
            .getItem("teerkost-ignore-shops")
            .then((value) => {
                // get existing data first
                let savedIgnoreShops = "";
                if (value !== null) {
                    savedIgnoreShops = value; // has to be an array
                    if (savedIgnoreShops.includes(shop)) {
                        const index = savedIgnoreShops.indexOf(shop);
                        savedIgnoreShops.splice(index, 1);
                        setIgnoredShopState(savedIgnoreShops);
                    }
                    localForage.setItem("teerkost-ignore-shops", savedIgnoreShops).catch((err) => {
                        console.log("Iets misgegaan bij het verwijderen van te negeren winkel: " + err);
                    });
                }
            })
            .catch((err) => {
                console.log("iets misgegaan bij ophalen van te negeren winkel: " + err);
            });
    };

    const deleteCategoryIgnore = (category) => {
        localForage
            .getItem("teerkost-ignore-categories")
            .then((value) => {
                // get existing data first
                let savedIgnoreCategories = "";
                if (value !== null) {
                    savedIgnoreCategories = value; // has to be an array
                    if (savedIgnoreCategories.includes(category)) {
                        const index = savedIgnoreCategories.indexOf(category);
                        savedIgnoreCategories.splice(index, 1);
                        setIgnoredCategoryListState(savedIgnoreCategories);
                    }
                    localForage.setItem("teerkost-ignore-categories", savedIgnoreCategories).catch((err) => {
                        console.log("Iets misgegaan bij het verwijderen van te negeren categorie: " + err);
                    });
                }
            })
            .catch((err) => {
                console.log("iets misgegaan bij ophalen van te negeren categorie: " + err);
            });
    };

    const saveShopIgnore = async (shop) => {
        try {
            let savedIgnoreShops = (await localForage.getItem("teerkost-ignore-shops")) || [];

            if (!savedIgnoreShops.includes(shop)) {
                savedIgnoreShops.push(shop);
                setIgnoredShopState(savedIgnoreShops);

                try {
                    await localForage.setItem("teerkost-ignore-shops", savedIgnoreShops);
                } catch (err) {
                    console.log("Iets misgegaan bij het bewaren van te negeren winkel: " + err);
                }
            }
        } catch (err) {
            console.log("Iets misgegaan bij ophalen van te negeren winkel: " + err);
        }
    };

    const saveCategoryIgnore = async (category) => {
        try {
            let savedIgnoreCategories = (await localForage.getItem("teerkost-ignore-categories")) || [];

            if (!savedIgnoreCategories.includes(category)) {
                savedIgnoreCategories.push(category);
                setIgnoredCategoryListState(savedIgnoreCategories);

                try {
                    await localForage.setItem("teerkost-ignore-categories", savedIgnoreCategories);
                } catch (err) {
                    console.log("Iets misgegaan bij het bewaren van te negeren categorie: " + err);
                }
            }
        } catch (err) {
            console.log("Iets misgegaan bij ophalen van te negeren categorie: " + err);
        }
    };

    useEffect(() => {
        updatePageTitle();
        collectIgnoredShops();
        collectIgnoredCategories();
    }, []);

    return (
        <div className="app-wrap">
            <Header></Header>
            <ShareDialog buttonText="Deel pagina" infoText="Deel een link naar deze pagina." />
            <div>
                <div className="bottom-buttons">
                    <GoHomeButton />
                    <SearchButton />
                    <OptionsButton active="active" />
                    <BookmarkButton />
                    <ShareButton buttonText="deel" infoText="Deel de huidige pagina met de gekozen filters." />
                </div>
                <div>
                    <header className="header-title">
                        <span>opties</span>
                    </header>
                    <main className="regular-container">
                        <div>
                            <h2>Winkels</h2>
                            <div className="sub-text">
                                Kies welke winkels je wel of niet wilt zien op het hoofdscherm.
                            </div>
                            <div className="shop-pick">
                                <span className="shop" data-shop="ah" onClick={() => setIgnoreShop("ah")}>
                                    Ah
                                </span>
                                <span className="shop" data-shop="jumbo" onClick={() => setIgnoreShop("jumbo")}>
                                    Jumbo
                                </span>
                                <span className="shop" data-shop="lidl" onClick={() => setIgnoreShop("lidl")}>
                                    Lidl
                                </span>
                                <span className="shop" data-shop="aldi" onClick={() => setIgnoreShop("aldi")}>
                                    Aldi
                                </span>
                                <span className="shop" data-shop="spar" onClick={() => setIgnoreShop("spar")}>
                                    Spar
                                </span>
                                <span className="shop" data-shop="plus" onClick={() => setIgnoreShop("plus")}>
                                    Plus
                                </span>
                                <span className="shop" data-shop="ekoplaza" onClick={() => setIgnoreShop("ekoplaza")}>
                                    Ekoplaza
                                </span>
                                <span className="shop" data-shop="poiesz" onClick={() => setIgnoreShop("poiesz")}>
                                    Poiesz
                                </span>
                                <span className="shop" data-shop="dirk" onClick={() => setIgnoreShop("dirk")}>
                                    Dirk
                                </span>
                                <span className="shop" data-shop="dekamarkt" onClick={() => setIgnoreShop("dekamarkt")}>
                                    Dekamarkt
                                </span>
                                <span className="shop" data-shop="hoogvliet" onClick={() => setIgnoreShop("hoogvliet")}>
                                    Hoogvliet
                                </span>
                            </div>
                        </div>
                        <div>
                            <h2>Categorieën</h2>
                            <div className="sub-text">
                                Kies welke categorieën je wel of niet wilt zien op het hoofdscherm.
                            </div>
                            <div className="category-pick">
                                {categoryList.map((category) => (
                                    <button
                                        className="category"
                                        data-category={category}
                                        key={category}
                                        onClick={() => setIgnoreCategory(category)}
                                    >
                                        {category}
                                    </button>
                                ))}
                            </div>
                        </div>
                        <div>
                            <h2>Thema</h2>
                            <div className="sub-text">Kies voor een licht of donker thema.</div>
                            <div className="dark-switch">
                                <div className="dark-switch-option" onClick={() => setDark(false)}>
                                    <div className="theme-light">
                                        <div className="title">teerkost</div>
                                        <div className="label-shop"></div>
                                        <div className="label-cat"></div>
                                        <div className="deal"></div>
                                    </div>
                                </div>
                                <div className="dark-switch-option" onClick={() => setDark(true)}>
                                    <div className="theme-dark">
                                        <div className="title">teerkost</div>
                                        <div className="label-shop"></div>
                                        <div className="label-cat"></div>
                                        <div className="deal"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </div>
    );
}

export default Options;
