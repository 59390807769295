function Percentage(props) {

    const percentage = props.percentage;

    if(percentage !== 0) {
        return (
            <span className="product-percentage">{percentage}</span> 
        )
    }
    else {
        return (<span></span>)
    }
}

export default Percentage;
  