import React, {useEffect} from "react";
import 'remixicon/fonts/remixicon.css'
import './App.css';
import { Routes, Route } from "react-router-dom";
import Footer from './components/Footer';
import Main from './pages/Main';
import Bookmarks from './pages/Bookmarks';
import Options from './pages/Options';
import ListProduct from './pages/ListProduct';
import Offers from './offers.json';
import { categoryList } from "./Categories";
import NotFound from "./pages/NotFound";
import SearchProduct from "./pages/SearchProduct";
import localForage from "localforage";

function App() {

  const setDarkModeOnLoad = () => {
    localForage.getItem('teerkost-darkmode').then(function (value) {
      let rootElement = document.documentElement;
      if (value === true) {
        rootElement.classList.add("dark");
        document.getElementsByTagName('meta')["theme-color"].content = "#393939";
      } else {
        rootElement.classList.remove("dark");
        document.getElementsByTagName('meta')["theme-color"].content = "#FFF";
      }
    });
  }
  
  useEffect(() => {
    setDarkModeOnLoad()
  });

  const convertProductToLink = (product) => {
    product = product.trim();
    let parsedProduct = product.normalize('NFD').replace(/[\u0300-\u036f]/g, '') // Remove accents
		.replace(/([^\w]+|\s+)/g, '-') // Replace space and other characters by hyphen
		.replace(/\-\-+/g, '-')	// Replaces multiple hyphens by one hyphen
		.replace(/(^-+|-+$)/g, ''); 
    
    parsedProduct = parsedProduct.toLowerCase(); 

    return parsedProduct;
  }

  const shops = ["jumbo", "aldi", "ah", "lidl", "plus", "ekoplaza", "poiesz", "dirk", "spar", "dekamarkt", "hoogvliet"]

  return (
    <div className="App">
      <Routes>
        <Route path="/">
          <Route index element={<Main />} />

          {Offers.map((item, key) => { // Map all routes to the offers
           return <Route key={item.id} path={ "" + item.shop +"/product/" + convertProductToLink(item.product) + ""} element={<Main singleItem={item} shop={item.shop} />} />
          })}

          {shops.map((shop, key) => { // Map all routes for shops
            return <Route key={shop} path={shop} element={<Main shop={shop} />} />
          })}

          {shops.map((shop) => { // Map all routes for shops and categories
            return categoryList.map((category, key) => {
              return <Route key={shop+category} path={shop + "/" + category} element={<Main shop={shop} cat={category} />} />
            })
          })}

          {categoryList.map((category, key) => { // Map all routes for categories for all shops
              return <Route key={category} path={"alle-winkels/" + category} element={<Main cat={category} />} />
            })}
          
          <Route path="alle-winkels" element={<Main />} /> { /* Map all routes for all shops with everything */ }
          
          <Route path="bewaard" element={<Bookmarks />} />
          <Route path="zoek" element={<SearchProduct />} />
          <Route path="opties" element={<Options />} />

          <Route path="lijst/:id" element={<ListProduct />} />
          <Route path="*" element={<NotFound/>} />
        </Route>
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
