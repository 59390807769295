import React, {Suspense} from "react"

import DateLabel from './product/DateLabel'
import Price from './product/Price'
import Percentage from './product/Percentage'
import Category from './product/Category'
import Deal from './product/Deal'
import ProductBookmark from "./product/ProductBookmark"

const Image = React.lazy(() => import ('./product/Image'))

function ProductCardSingle(props) {

  const trimDownString = (inputString) => {
    if(inputString.length >= 80) {
      var trimmedString = inputString.slice(0,80)
      trimmedString = trimmedString.concat("...")
      return trimmedString
    } else {
      return inputString
    }
  }

    return (
      <article className="flex-item flex-item-single" data-cat={props.item['category']}>
        <div className={"product-header " + props.item['shop']}>
          <span className="product-shop">{props.item['shop']}</span>
          <DateLabel dateEnd={props.item['dateEnd']} dateStart={props.item['dateStart']}/>
        </div>
        <Category category={props.item['category']}/>
        <Price newPrice={props.item['price']} />
        <Percentage percentage={props.item['percentage']}/>
        <a className="product-link" href={props.item['link']} target="blank">
        <Suspense fallback={<div className="even-geduld-image"></div>}>
          <Image image={props.item['image']} />
        </Suspense> 
        <summary className="product-name">
            <span className="product-title">{props.item['product']}</span>
            <span className="product-info"> {trimDownString(props.item['productInfo'])}</span>
        </summary>
        </a>
        <Deal deal={props.item['deal']} />
        <ProductBookmark id={props.item['productId']}/>
      </article>
    )
}

export default ProductCardSingle;
