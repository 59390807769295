import React, {useState, useEffect, Suspense} from "react";
import { useParams } from "react-router-dom";
import Offers from '../offers.json';
import ShareDialog from "../components/ShareDialog";
import BookmarkButton from "../BookmarkButton";
import GoHomeButton from "../GoHomeButton";
import ShareButton from "../ShareButton";
import SearchButton from "../SearchButton";
import OptionsButton from "../OptionsButton";
import Header from "../components/Header";

const ProductsContainer = React.lazy(() => import ('../ProductsContainer'));

function Main() {
  
  const { id } = useParams();

  const createProductIdsList = (id) => {
    let productIdFilter = object => object.productId === null;

    id = id.split(',')
    console.log(id)
    const aantal = id.length
    console.log(aantal)

    productIdFilter = object => id.includes(object.productId);
    const filtered = Offers.filter(productIdFilter)
    setSelectedOffers(filtered);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const updatePageTitle = () => {
    document.title = "gedeelde lijst - Teerkost"
  }

  const [selectedOffers, setSelectedOffers] = useState(Offers);

  useEffect(() => {
    createProductIdsList(id)
    updatePageTitle()
  }, [])

  return (
    <div className="app-wrap">
        <Header></Header>
        <ShareDialog buttonText="Deel lijst" infoText="Deel een link naar deze lijst met aanbiedingen." />
        <div className="bottom-buttons">
          <GoHomeButton />
          <SearchButton />
          <OptionsButton />
          <BookmarkButton />
          <ShareButton buttonText="deel" infoText="Deel een link naar deze lijst met aanbiedingen."/>
        </div>
        <header className="header-title">
                  <span>
                    gedeelde lijst
                  </span>
              </header>
        <Suspense fallback={<div className="even-geduld"><div className="notify-wrap"><span className='dot-dot-dot'></span></div></div>}>
            <ProductsContainer selectedOffers={selectedOffers}/>
        </Suspense>
    </div>
  )
}

export default Main;
  