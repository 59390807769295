import { Link } from "react-router-dom";
import React, { useEffect } from "react";

function OptionsButton(props) {
    useEffect(() => {}, []);

    if (props.active !== undefined) {
      return (
        <div className="button-cell active">
            <Link to="/opties">
                <div className="button search-button">
                    <i className="ri-tools-fill"></i>
                    <div className="button-tag">opties</div>
                </div>
            </Link>
        </div>
    );
    } else {
      return (
        <div className="button-cell">
            <Link to="/opties">
                <div className="button search-button">
                    <i className="ri-tools-line"></i>
                    <div className="button-tag">opties</div>
                </div>
            </Link>
        </div>
    );
    }
}

export default OptionsButton;
