function NoBookmarks() {
    return (
        <div className="no-offers">
            <div className="notify-wrap">Geen bewaarde korting gevonden</div>
            <div className="notify-explainer">
                Korting kan je bewaren door op <i className="ri-bookmark-line"></i> te klikken bij een korting.
            </div>
        </div>
    );
}

export default NoBookmarks;
